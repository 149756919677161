import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class AddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.addressInputRef = React.createRef(); // Use a ref for the input element
  }
  state = {
    name: "",
    address: "",
    autocomplete: null,
    errorMessage: ""
  };

  componentDidUpdate(prevProps) {
    // Check if googleLoaded has changed from false to true
    if (!prevProps.googleLoaded && this.props.googleLoaded) {
      // console.log(this.props.googleLoaded);
      this.initAutocomplete();
    }
  }

  initAutocomplete = () => {
    if (window.google && window.google.maps && this.addressInputRef.current) {
      // console.log('Google Maps initialized for AddressForm');

      const addressAutocomplete = new window.google.maps.places.Autocomplete(
        this.addressInputRef.current, // Use the ref to get the input
        { types: ["geocode"] }
      );

      addressAutocomplete.addListener("place_changed", () => {
        const place = addressAutocomplete.getPlace();
        if (place && place.formatted_address) {
          // console.log('Selected address:', place.formatted_address);
          this.setState({ address: place.formatted_address });
        }
      });

      this.setState({ autocomplete: addressAutocomplete });
    } else {
      // console.log('Google Maps or input not available yet');
      // Optional: retry initialization after a short delay if the input is not yet available
      setTimeout(this.initAutocomplete, 500); // Try again after 500ms
    }
  };

  handleChange = (e) => {
    // console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: ""
    });
  };

  isAddressComplete(address) {
    const addressComponents = address.split(',').map(component => component.trim());
    return addressComponents.length === 4; // Assuming 4 required components
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const toastr = toast.loading("Processing...", { autoClose: 5000 });

    if (!this.validateForm() || !this.isAddressComplete(this.state.address)) {
      toast.update(toastr, { render: "Please check your info again", type: "error", isLoading: false, autoClose: 1000 });
      return;
    }

    const { name, address } = this.state;

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "customer/add-address",
        {
          userId: this.props.userId,
          jwt: localStorage.getItem("jwt"),
          nickname: name,
          address
        }
      );
      this.props.update();
      toast.update(toastr, {
        render: `Address "${response?.data?.data?.attributes?.Nickname}" saved successfully!`,
        type: "success",
        isLoading: false,
        autoClose: 1000
      });

      this.setState({ name: "", address: "" });
    } catch (error) {
      toast.update(toastr, {
        render: error.response.data,
        type: "error",
        isLoading: false,
        autoClose: 5000
      });
      this.setState({ errorMessage: error.response.data.message || "Address failed" });
    }
  };

  validateForm = () => {
    const { name, address } = this.state;
    const errors = [];

    if (!name.trim()) {
      errors.push("Nickname cannot be empty");
    }

    if (!address.trim()) {
      errors.push("Address cannot be empty");
    }

    if (errors.length > 0) {
      this.setState({ errorMessage: errors.join(", ") });
      return false;
    }

    return true;
  };

  render() {
    const { name, address, errorMessage } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="p-2 rounded bg-color3">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          limit={2}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="colored"
        />
        <div className="form-group m-2">
          <label className="text-color1 fw-bold">Nickname:</label>
          <br />
          <input
            type="text"
            className="w-100 my-2 bg-color2"
            name="name"
            value={name}
            onChange={this.handleChange}
            required
          />
          {!name.trim() && <div className="error">Name cannot be empty</div>}
        </div>
        <br />

        <div className="form-group m-2">
          <label className="text-color1 fw-bold">Address:</label>
          <br />
          <input
            ref={this.addressInputRef}
            id="minys_address_input"
            type="address"
            className="w-100 my-2 bg-color2"
            name="address"
            value={address}
            onChange={(e) => this.handleChange(e, 'address')} // Use onBlur event with handleChange function
            onBlur={(e) => this.handleChange(e, 'address')} // Use onBlur event with handleChange function
            required
          />
          {!address.trim() && <div className="error">Address cannot be empty</div>}
        </div>
        <br />

        <button
          type="submit"
          className="btn bg-color4 poppins w-20 fw-semibold text-color6 w-100"
        >
          Save Address
        </button>
        {errorMessage && <div className="error">{errorMessage}</div>}
      </form>
    );
  }
}

export default AddressForm;
