import React from 'react';
import './assets/DeliveryDetails.css';
import validator from 'validator';
import { NumericFormat } from 'react-number-format';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const MapComponent = React.memo(({ loc, apiKey }) => {
    const MapWithScript = withScriptjs(withGoogleMap(() => (
        <GoogleMap
            defaultZoom={18}
            defaultCenter={{
                lat: loc?.lat,
                lng: loc?.lng
            }}
        >
            <Marker
                position={{
                    lat: loc?.lat,
                    lng: loc?.lng
                }}
            />
        </GoogleMap>
    )));

    return (
        <MapWithScript
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `200px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
    );
});

class DeliveryDetails extends React.Component {
    state = {
        delivery_details: {
            address: null,
            name: this.props?.user?.name,
            email: this.props?.user?.email,
            phone: this.props?.user?.phone,
            option: '1',
            notes: null,
        },
        autocomplete: null,
        orderId: null,
        location: null,
        deliveryQuote: null,
        savedOrNew: (this.props?.user && this.props?.user?.Address > 0) ? "saved" : "new",
        coordinates: { lat: 0, lng: 0 }
    }

    checkIfPlaceWasSelected() {
        return this.state?.autocomplete?.getPlace() || false;
    }

    handleChange(event, value) {
        if (value === 'address') {
            if (this.isAddressComplete(event.target.value) !== true) {
                this.setState({ deliveryQuote: null });
            }
        }
        const enteredValue = event.target.value;
        const updatedDeliveryDetails = {
            ...this.state.delivery_details,
            [value]: enteredValue
        };

        this.setState({
            delivery_details: updatedDeliveryDetails
        });

    }

    async handleSavedAddressChange(event, value) {
        const selectedAddress = this.props?.user?.addresses?.find(a => a.id === parseInt(event.target.value));

        if (value === 'address') {
            if (this.isAddressComplete(selectedAddress?.Address) !== true) {
                this.setState({ deliveryQuote: null });
            }
        }
        const enteredValue = selectedAddress?.Address;
        const updatedDeliveryDetails = {
            ...this.state.delivery_details,
            [value]: enteredValue
        };

        this.setState({
            delivery_details: updatedDeliveryDetails
        });
        this.setState({ coordinates: await this.getCoordinatesFromAddress(selectedAddress?.Address) });
        this.props?.getDeliveryQuote(selectedAddress?.Address,this.props?.orderId);
    }

    isAddressComplete(address) {
        if(address){
            const addressComponents = address.split(',').map(component => component.trim());
            return addressComponents?.length === 4; // Assuming 4 required components
        }
    }

    getQuote() {
        // console.log("Get Quote");
        var place = this.state.autocomplete.getPlace();
        this.setState({ coordinates: { lat: place?.geometry?.location?.lat(), lng: place?.geometry?.location?.lng() } })
        if (place) {
            this.props?.getDeliveryQuote(place);
        }
    }

    async getCoordinatesFromAddress(address) {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );
        const data = await response.json();
        if (data.results && data.results.length > 0) {
            const location = data.results[0].geometry.location;
            return { lat: location.lat, lng: location.lng };
        } else {
            throw new Error('No se pudieron obtener las coordenadas');
        }
    };

    addDeliveryDetails(e) {
        this.props?.addDeliveryDetails(this.state);
    }

    async componentDidMount() {
        const deliveryAutocomplete = new window.google.maps.places.Autocomplete(document.getElementById("address_input"), { types: ['geocode'] });
        this.setState({
            autocomplete: deliveryAutocomplete,
            orderId: this.props.orderId,
            location: this.props.location
        }, () => {
            // console.log(this.state.autocomplete);
        });

        deliveryAutocomplete.addListener("place_changed", () => {
            this.getQuote();
        })

        const loc = this.props?.locations?.find((l) => l.id === this.props?.location);
        var address = [
            loc.address.address_line_1 || loc.address.addressLine1,
            loc.address.locality,
            loc.address.administrative_district_level_1 || loc.address.administrativeDistrictLevel1,
            loc.address.postal_code || loc.address.postalCode,
            loc.address.country
        ];
        if (!loc?.coordinates) {
          this.setState({coordinates: await this.getCoordinatesFromAddress(address.join(", "))});
        }
        else {
            this.setState({coordinates: {lat: loc?.coordinates?.latitude, lng: loc?.coordinates?.longitude}})
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.deliveryQuote && this.props.deliveryQuote !== prevProps.deliveryQuote) {
            this.setState({
                deliveryQuote: this.props.deliveryQuote
            });
        }
    }

    changeAddressType(type) {
        document.getElementById("address_select").value = "0";
        document.getElementById("address_input").value = "";
        this.setState({ savedOrNew: type, deliveryQuote: null });
    }

    render() {
        let _addresses = [];
        if (this.props?.user?.addresses) {
            this.props?.user?.addresses?.forEach(address => {
                _addresses.push(<option key={"address_" + address?.id} value={address?.id}>{address?.Nickname} - {address?.Address}</option>)
            });
        }
        return (
            <div className="wrapper mb-2 pt-3">
                <MapComponent loc={this.state.coordinates} apiKey={process.env.REACT_APP_GOOGLE_API_KEY} />
                {_addresses?.length > 0 &&
                    <div className="row">
                        <div style={{ 'cursor': 'pointer' }} onClick={(e) => this.changeAddressType("saved")} className={this.state.savedOrNew === "saved" ? 'bg-color6 poppins text-color3 col-6' : 'poppins text-color6 col-6'}>
                            Saved address
                        </div>
                        <div style={{ 'cursor': 'pointer' }} onClick={(e) => this.changeAddressType("new")} className={this.state.savedOrNew === "new" ? 'bg-color6 poppins text-color3 col-6' : 'poppins text-color6 col-6'}>
                            New address
                        </div>
                    </div>
                }
                <select id="address_select" hidden={this.state.savedOrNew === "new"} className="w-100 my-2" onChange={(e) => this.handleSavedAddressChange(e, 'address')}>
                    <option value="0" selected disabled>Select a saved address</option>
                    {_addresses}
                </select>
                <div hidden={this.state.savedOrNew === "saved"}>
                    <input
                        className="w-100 my-2"
                        id="address_input"
                        type="text"
                        onChange={(e) => this.handleChange(e, 'address')} // Use onBlur event with handleChange function
                        onBlur={(e) => this.handleChange(e, 'address')} // Use onBlur event with handleChange function
                        placeholder="Address"
                        required
                    />
                    {this.state?.delivery_details?.address !== null && this.state?.delivery_details?.address === "" && (
                        <div className="error">Address not valid.</div>
                    )}
                    {this.props.deliveryQuote === "Allowed distance between addresses exceeded" && (
                        <div className="error">Allowed distance between addresses exceeded.</div>
                    )}
                    {(this.checkIfPlaceWasSelected() === false || this.state?.deliveryQuote === null) && (
                        <div className="error">Select the address from the dropdown.</div>
                    )}
                </div>
                <input className="w-100 my-2" type="text" value={this.state?.delivery_details?.name} onChange={(e) => this.handleChange(e, 'name')} placeholder="Full name" required />
                {this.state?.delivery_details?.name !== null && !validator.isAlpha(this.state?.delivery_details?.name || '', 'en-US', { ignore: 's' }) &&
                    <div className="error">Name not valid.</div>
                }
                <input className="w-100 my-2" type="text" value={this.state?.delivery_details?.email} onChange={(e) => this.handleChange(e, 'email')} placeholder="Email" />
                {this.state?.delivery_details?.email !== null && !validator.isEmail(this.state?.delivery_details?.email || '') &&
                    <div className="error">Email is not valid.</div>
                }
                <input className="w-100 my-2" type="text" value={this.state?.delivery_details?.phone} onChange={(e) => this.handleChange(e, 'phone')} placeholder="Phone number" />
                {this.state?.delivery_details?.phone !== null && !validator.isMobilePhone(this.state?.delivery_details?.phone || '') &&
                    <div className="error">Phone number is not valid.</div>
                }
                <hr />
                <div id='deliveryInformation'>
                    <label htmlFor="deliveryInformation" className='text-color6  fw-semibold ms-2 poppins'>
                        Delivery by DoorDash, 100% contactless.
                    </label>
                </div>
                <hr></hr>
                <div className='row poppins text-color6'>
                    <div className='col-6'>
                        Dropoff estimate
                    </div>
                    <div className='col-6 text-end'>
                        {this.state?.deliveryQuote && this.state?.deliveryQuote?.dropoff_time_estimated && this.state?.delivery_quote !== "Allowed distance between addresses exceeded" && (
                            new Date(this.props.deliveryQuote?.dropoff_time_estimated).toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric'
                            })
                        )}
                    </div>
                </div>
                <hr></hr>
                <div className='row poppins text-color6'>
                    <div className='col-6'>
                        Delivery fee
                    </div>
                    <div className='col-6 text-end'>
                        {this.state?.deliveryQuote &&
                            <NumericFormat value={this.state?.deliveryQuote?.fee / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} />
                        }
                    </div>
                </div>
                <hr></hr>
                <div className='row poppins text-color6'>
                    <div className='col-12'>
                        DropOff Notes
                    </div>
                    <div className='col-12'>
                        <textarea rows="3" className='bg-color2 text-color6 w-100' placeholder="Type note" onChange={(e) => this.handleChange(e, 'notes')} />
                    </div>
                </div>
                <br /><br />
                <button className='w-100 btn bg-color4 text-color6' onClick={(e) => this.addDeliveryDetails(e)} disabled={!(validator.isEmail(this.state?.delivery_details?.email || '') && validator.isMobilePhone(this.state?.delivery_details?.phone || '') && validator.isAlpha(this.state?.delivery_details?.name || '', 'en-US', { ignore: 's' }) && this.state?.delivery_details?.address !== "" && this.state?.delivery_details?.address !== null && (this.state.savedOrNew === "saved" || this.checkIfPlaceWasSelected() !== false) && this.state?.deliveryQuote !== null && this.state?.delivery_quote !== "Allowed distance between addresses exceeded")}>Continue to payment</button>
            </div>
        );
    }
}

export default DeliveryDetails;