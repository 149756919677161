import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

class CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.cardAddressInputRef = React.createRef(); // Use a ref for the input element
    this.state = {
      appId:
        process.env.REACT_APP_PROD === "true"
          ? process.env.REACT_APP_APPLICATION_ID
          : process.env.REACT_APP_APPLICATION_ID_DEV,
      locationId: this.props?.location,
      card: null,
      errorMessage: null,
      token: "",
      address: "",
      name: "",
      autocomplete: null, // Add autocomplete state
    };
  }


  async componentDidMount() {
    await this.initSquare();
  }
  componentDidUpdate(prevProps) {
    // Initialize Google Places Autocomplete when the script is loaded
    if (!prevProps.googleLoaded && this.props.googleLoaded) {
      this.initGoogleAutocomplete();
    }
  }
  initGoogleAutocomplete = () => {
    if (window.google && window.google.maps && this.cardAddressInputRef.current) {
      // console.log('Google Maps initialized for CardForm');

      const cardAddressAutocomplete = new window.google.maps.places.Autocomplete(
        this.cardAddressInputRef.current, // Use the ref to get the input element
        { types: ["geocode"] }
      );

      cardAddressAutocomplete.addListener("place_changed", () => {
        const place = cardAddressAutocomplete.getPlace();
        if (place && place.formatted_address) {
          // console.log("Selected address: ", place.formatted_address);
          this.setState({ address: place.formatted_address });
        }
      });

      this.setState({ autocomplete: cardAddressAutocomplete });
    } else {
      // console.log('Google Maps or input not available yet');
      // Retry initialization if input is not yet available
      setTimeout(this.initGoogleAutocomplete, 500); // Retry after 500ms
    }
  };


  initSquare = async () => {
    if (!window.Square) {
      const script = document.createElement("script");
      script.src =
        process.env.REACT_APP_PROD === "false"
          ? `https://sandbox.web.squarecdn.com/v1/square.js`
          : `https://web.squarecdn.com/v1/square.js`;
      script.defer = true;
      script.async = true;
      document.head.appendChild(script);

      await new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
      });

      if (!window.Square) {
        throw new Error("Square.js failed to load properly");
      }
    }

    this.attachCard();
  };

  attachCard = async () => {
    const payments = window.Square.payments(
      this.state.appId,
      this.props?.locations[0]?.id
    );

    let card;
    try {
      card = await payments.card();
      this.setState({ card });
      await card.attach("#card-container");
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const toastr = toast.loading("Processing...", { autoClose: 5000 });

    // if (!this.validateForm()) {
    //   toast.update(toastr, {
    //     render: this.state.errorMessage,
    //     type: "error",
    //     isLoading: false,
    //     autoClose: 1000,
    //   });
    //   return;
    // }
    if (!this.validateForm() || !this.isAddressComplete(this.state.address)) {
      toast.update(toastr, { render: "Please check your info again", type: "error", isLoading: false, autoClose: 1000 });
      return;
    }

    try {
      const token = await this.tokenize(this.state.card);
      const { address, name } = this.state;
      // console.log(address);
      await axios.post(
        process.env.REACT_APP_API_URL + "customer/create-card",
        {
          token,
          address,
          name,
          customerId: this.props.user.squareId,
        }
      );

      this.props.getProfile();
      toast.update(toastr, {
        render: "Card saved successfully!",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });

      // Clear card inputs by detaching the current card and resetting
      await this.resetCardFields();

      // Clear form state
      this.setState({ token: "", address: "", name: "" });
    } catch (error) {
      toast.update(toastr, {
        render: error?.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  async tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    }
  }

  handleChange = (e) => {
    // console.log(e.target);
    this.setState({ [e.target.name]: e.target.value });
  };

  isAddressComplete(address) {
    const addressComponents = address.split(',').map(component => component.trim());
    return addressComponents.length === 4; // Assuming 4 required components
  }

  validateForm = () => {
    const { address, name } = this.state;
    let errorMessage = "";

    if (!name) {
      errorMessage += "Cardholder name cannot be empty. ";
    }

    if (!address) {
      errorMessage += "Billing Address cannot be empty.";
    }

    if (errorMessage) {
      this.setState({ errorMessage });
      return false;
    }

    return true;
  };

  resetCardFields = async () => {
    // Detach the current card instance
    if (this.state.card) {
      await this.state.card.destroy();
    }
    // Re-attach a new card instance to the container
    this.attachCard();
  };

  render() {
    const { address, name, errorMessage } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="p-2 rounded bg-color3">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          limit={2}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="colored"
        />

        <div className="form-group m-2">
          <label className="text-color1 fw-bold">Cardholder Name:</label>
          <br />
          <input
            type="text"
            className="w-100 my-2 bg-color2"
            name="name"
            value={name}
            onChange={this.handleChange}
            required
          />
          {!name && <div className="error">Cardholder name is required</div>}
        </div>
        <br />

        <div className="form-group m-2">
          <label className="text-color1 fw-bold">Billing Address:</label>
          <br />
          <input
            ref={this.cardAddressInputRef}
            id="address-form-input"
            type="text"
            className="w-100 my-2 bg-color2"
            name="address"
            value={address}
            onChange={this.handleChange}
            required
          />
          {!address && <div className="error">Billing Address is required</div>}
        </div>
        <br />

        <div id="card-container"></div>
        {errorMessage && (
          <div className="error-message text-danger">{errorMessage}</div>
        )}
        <button
          type="submit"
          className="btn bg-color4 poppins w-20 fw-semibold text-color6 w-100"
        >
          Save Card
        </button>
      </form>
    );
  }
}

export default CardForm;
